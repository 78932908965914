import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
          <main>{children}</main>
          <footer style={{ backgroundColor: '#87B400'}}>
            <div className="footer-content">
              <span>
                  לה צ׳יפולה, בית עובד 8, תל אביב
                  <br/>
                  <a href="tel:058-7-267676" style={{color: '#fff', textDecoration: 'none', marginRight: 15, cursor: 'pointer'}}>
                    טל: 058-7-267676
                  </a>     
              </span>
            </div>
            <div style={{fontSize: 12, paddingLeft: 15}}>
              © {new Date().getFullYear()}, Built with{` `}
              <a href="http://lacipolla.co.il">La Cipolla</a>
            </div>
          </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
