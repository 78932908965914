import * as React from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/styles'
import { Link as MLink, Theme} from '@material-ui/core'
import BgHeaderDesk from '../images/header-desk.jpg'
import Layout from "../components/layout"
import SEO from "../components/seo"
import MenuProductList from '../components/menu-product-list'
import Slider from '../components/slider'
import SliderData from '../content/slider-data.json'
import ISlide from '../interfaces/ISlide'
import HeaderEdit from '../components/header-edit'

const useStyles = makeStyles((theme: Theme) => {
  return {
      divBGHeader:{
          backgroundImage: `url(${BgHeaderDesk})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh',
          paddingTop: 30,
          backgroundPosition: 'center',
      },
      backgroundImage:{
          width:'100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left:'0',
          zIndex: -1,
          display: 'none'
      },
      divTopTitle:{
          marginTop:70,
          marginRight: 'auto',
          marginLeft: 'auto',
      },
      homeTopTitleText:{
          position: 'relative',
          marginTop: 0,            
          fontSize: '4.4rem',
          lineHeight: '6.9rem',
          fontWeight: 600,
          color: '#2F519C',
          marginBottom: '0.5rem',
          zIndex: 10,
          textAlign: 'center',            
      },
      homeTopTitleTextSub:{
          marginTop: 0,            
          fontSize: '1.5rem',
          lineHeight: '1rem',
          fontWeight: 600,
          color: '#000',
          marginBottom: '1.6rem',
          textAlign: 'center',            
      },
      homeBtnSelectRecipe:{
          backgroundColor: '#2F519C',
          color: '#fff',
          width: '220px',
          height: '60px',
          border: 'solid 1px #2F519C',
          borderRadius: '25px',
          fontSize: '18pt',
          fontWeight: 600,
          marginTop: 50,
          padding: '12px 50px',       
          '&:hover': {
              backgroundColor: '#3b65c3',
              textDecoration: 'none',
          }
      },
      homeSectionHowItWorks: {
          backgroundColor: '#ECF0F9',
          paddingBottom: '6rem',
          paddingTop: '6rem',
          marginTop: '302px',
      },
      btnSelectRecipeContainer:{
          marginTop: 50,
      },
      divFooter:{
          display: 'block',
      },
      divFooterMobile: {
          display: 'none',
      },
      divMainContainer:{
          
      },
      sliderRoot:{
        paddingTop: 50, 
        paddingBottom: 0,
        paddingRight: 250,
        paddingLeft: 250,               
      },
      sliderHeaderMobile:{
        display:'none',
      },
      '@media (max-width: 1350px)':{
          container:{
              width: 'unset',
          },
          homeTopTitleText:{
              fontSize: '2.4rem',
              lineHeight: '3rem',
              textAlign: 'unset',
              marginRight: 'unset',
          },
          homeTopTitleTextSub:{                 
              textAlign: 'unset',
              marginRight: 'unset',               
          },             
      },
      '@media (max-width: 800px)':{
          sliderRoot:{
            paddingTop: 30, 
            paddingBottom: 0,
            paddingRight: 20,
            paddingLeft: 20,               
          },
      },
      '@media (max-width: 420px)':{            
          homeTopTitleTextSub:{
              display: 'none',                
          },           
          divFooter:{
              display: 'none',
          },
          divFooterMobile: {
              display: 'block',
          },
          divMainContainer:{
              overflowX: 'hidden'
          },
          sliderRoot:{
              display: 'none'
          },
          divBGHeader:{
            display: 'none'
          },
          sliderHeaderMobile:{
            display:'block',
          },
      }
  }
})

const IndexPage = () => {
  const classes = useStyles()

  return(
      <Layout>
          <SEO title="Home" />
          <div  className={classes.divMainContainer}>
            <div style={{}} className={classes.divBGHeader}></div>         
            <div className={classes.sliderHeaderMobile}>
                <div style={{marginBottom: 75}}>
                    <HeaderEdit/>
                </div>
                <Slider slides={SliderData.images as ISlide[]} options={{autoPlay: true, infiniteLoop: true, centerMode: false, showThumbs: false }}/>
            </div>
            <div style={{}}>
                <MenuProductList/>
            </div>
            <div>
                <div className={classes.sliderRoot}>
                    <Slider slides={SliderData.images as ISlide[]} options={{autoPlay: true, infiniteLoop: true, centerMode: true, centerSlidePercentage: 70 }}/>
                </div>
            </div>
            <div>
                <picture>
                    <source media='(max-width: 768px)' srcSet="/images/mapmobile.png"></source>
                    <source media='(min-width: 768px)' srcSet="/images/map.png"></source>
                    <img style={{marginBottom:0, width:'100%'}} src="/images/map.png" 
                        alt="לה צ׳יפולה, בית עובד 8, תל אביב, טל: 058-7-267676" 
                        title="לה צ׳יפולה, בית עובד 8, תל אביב, טל: 058-7-267676"/>
                </picture>
            </div>
            <div className={classes.divFooter}>
                {/* <Footer /> */}
            </div>
            <div className={classes.divFooterMobile}>
                {/* <FooterMobile /> */}
            </div>
          </div>
      </Layout>
  )
}

export default IndexPage
