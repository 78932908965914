import React, {useState} from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/styles'
import {IconButton, Theme} from '@material-ui/core'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import ImgLogo from '../images/logo-cipolla.webp'


const useStyles = makeStyles((theme: Theme) => {
  return {
      navRoot:{            
          margin: '0 auto',
          justifyContent: 'center',
          display:'flex',
          marginTop:50,
          position: 'absolute',
          width: '100%'
      },
      menuUl:{
          listStyle: 'none',
          margin: 0,
          padding: 0,
          display: 'flex',
          direction: 'rtl',
      },
      menuItem: {
          width: 250,
          fontWeight: 700,
          fontSize: '17pt',
          color: '#fff'
      },
      menuUlMobile:{
          listStyle: 'none',
          display: 'none',
      },
      logoContainer: {           
          position: 'absolute',
          right: '50px',
          top: '15px',            
      },
      logoTitleContainer: {
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-24px',
      },
      logo: {
          fontSize: '24px',
          fontWeight: 'bold',
          color: 'red',         
      },
      logoImg: {            
          width: '200px',
          marginRight: 10,
          position: 'absolute',
          right: 10,
          border: 'solid 1px transparent',
          borderRadius: '50%',
          boxShadow: '-1px 0px 7px 1px #fff'
      },
      hide: {
          display: 'none',
      },
      mobileMenuIcon: {
          width: 48,
          height: 48,
          color: '#2F519C',
      },
      mobileMenuIconContainer:{
          position: 'absolute',
          top: 10,
          left: 10,
          display: 'none',
      },
      mobileTelDiv: {
          fontSize: '2rem',
          marginTop: '13px',
          marginLeft: '20px',
          fontWeight: 500
      },
      drawerHeader: {
          display: 'flex',
          alignItems: 'center',
          padding: '0, 8',            
          justifyContent: 'flex-start',
      },
      link: {
          textDecoration: 'none', 
          cursor: 'pointer'
      },
      linkTopBar: {
          cursor: 'pointer',
          color: '#fff',
          textDecoration: 'unset',
          '&:hover':{
            textDecoration: 'underline',
          }
      },
      mobileNavLeft: {
          width: '250px',
          '& a':{
              textAlign: 'center',
              borderBottom: 'solid 1px #ccc',
          }
      },
      '@media (max-width: 1700px)':{
          logoContainer:{
              right: '70px',
          },
          
      },
      '@media (max-width: 1360px)':{
          menuUl:{
              display: 'none',
          },
          mobileMenuIconContainer:{
              display: 'flex',
          },
          logoContainer: {
              display: 'none',
          },
          menuUlMobile:{                
              display: 'block',
          },
      },
      '@media (max-width: 800px)':{
        logoImg: {            
            width: '100px',
            top: -35,
            right: 5,
        },
        menuItem: {
            fontSize: '15pt',
        },
      },
      '@media (max-width: 420px)':{
        logoImg: {            
            // width: '70px',
            // top: -35,
            // position: 'absolute',
            // right: 5,
            // border: 'solid 1px transparent',
            // borderRadius: '50%',
            // boxShadow: '-1px 0px 7px 1px #fff'
            display: 'none'
        },
        menuItem: {
            width: 250,
            fontWeight: 700,
            fontSize: '12pt',
            color: '#fff'
        },
    },
  }
})

const Header = ({ siteTitle }) => {

  const classes = useStyles(); 

  const [open, setOpen] = useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose =()=>{
    setOpen(false);
  }

  return(<div>
            <div className={classes.mobileMenuIconContainer}>
                <ul className={classes.menuUlMobile}>                    
                    <li className={classes.menuItem}>
                        בית עובד 8, תל אביב 
                    </li>
                    <li className={classes.menuItem}>058-7-267676</li>
                    
                </ul>               
            </div>
            
            <div className={classes.navRoot + ' container'}>
                <div className={classes.logoContainer}>
                <Link to='/' className={classes.link}>
                    
                </Link>                    
                </div>
                <img src={ImgLogo} alt="" className={classes.logoImg}/> 
                
                <ul className={classes.menuUl}>                    
                    <li className={classes.menuItem}>
                        <Link to="/" className={classes.linkTopBar}>
                            בית עובד 8, תל אביב
                        </Link>                       
                    </li>
                    <li className={classes.menuItem} style={{'display':'none'}}>
                        <Link to="/" className={classes.linkTopBar}>
                            תפריט   
                        </Link>
                    </li>
                    <li className={classes.menuItem} style={{'display':'none'}}>הזמנות שלי</li>
                    <li className={classes.menuItem}>
                    <a href="tel:058-7-267676" style={{color: '#fff', textDecoration: 'none', cursor: 'pointer'}}>
                    טל: 058-7-267676
                    </a>  
                    </li>
                    <li className={classes.menuItem} style={{'display':'none'}}>
                        User login
                    </li>
                </ul>
            </div>
            {/* <SideMenuMobile show={open} onClose={handleDrawerClose} />
            {showScrollBar? <ScrollNavBar showGeneralBtn={true} showHomeBtn={false}/> : ''}*/}
        </div>
   )
}

export default Header
